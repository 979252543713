body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
}

.headings {
  color: #1158b0;
  /* color: #0C0181; */
  font-weight: 600;
  text-align: center;
}

.IsBooked{
  background-color: #9DFFFF;
}
/* ----------------all pages----------------- */

@media (max-width: 344px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads2_For_page1 {
    min-height: 383px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 67px; */
  }

  .ads3_For_page1 {
    /* min-height: 45px; */
    min-height: auto;
  } 

  .page2 .col-3 {
    min-height: 91px;
    width: 30% !important;
  }

  .page3 .col-5 {
    border-radius: 10px;
    width: 47.666667%;
    /* width: 46.666667%; */
    min-height: 185px;
  }

  .page2 .col-3  p{
   font-size: 10px;
  }
  .page2 .col-3  button{
    font-size: 10px;
   }
  .ads3_For_page3 {
    min-height: 41px !important;
  }

  .page4 .col-11 {
    border-radius: 10px;
    height: 228px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
}

@media (min-width: 345px) and (max-width: 359px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 42px; */
  }

  .ads2_For_page1 {
    min-height: 404px;
  }

  .ads3_For_page1 {
    min-height: auto;
    /* min-height: 48px; */
  }

  .page2 .col-3 {
    min-height: 99px;
  }

  .page3 .col-5 {
    border-radius: 10px;
    width: 47.666667%;
    min-height: 185px;
  }

  .ads3_For_page3 {
    min-height: 41px !important;
  }

  .page4 .col-11 {
    border-radius: 10px;
    height: 228px;
  }
}

@media (min-width: 360px) and (max-width: 374px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads2_For_page1 {
    min-height: 423px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 57px; */
  }

  .ads3_For_page1 {
    /* min-height: 50px; */
    min-height: auto;
  }

  .page2 .col-3 {
    min-height: 100px;
  }

  .page3 .col-5 {
    border-radius: 10px;
    width: 47.666667%;
    min-height: 204px;
  }

  .ads3_For_page3 {
    min-height: 41px !important;
  }

  .page4 .col-11 {
    border-radius: 10px;
    min-height: 259px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
}

@media (min-width: 375px) and (max-width: 389px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads2_For_page1 {
    min-height: 443px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 64px; */
  }

  .ads3_For_page1 {
    /* min-height: 55px; */
    min-height: auto;
  }

  .page2 .col-3 {
    min-height: 107px;
  }

  .page3 .col-5 {
    border-radius: 10px;
    width: 46.666667%;
    min-height: 204px;
  }

  .ads3_For_page3 {
    min-height: 41px !important;
  }

  .page4 .col-11 {
    border-radius: 10px;
    min-height: 259px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
}

@media (min-width: 390px) and (max-width: 411px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads2_For_page1 {
    min-height: 471px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 67px; */
  }

  .ads3_For_page1 {
    /* min-height: 55px; */
    min-height: auto;
  }

  .page2 .col-3 {
    min-height: 114px;
  }

  .page3 .col-5 {
    border-radius: 10px;
    width: 46.666667%;
    min-height: 185px;
  }

  .ads3_For_page3 {
    min-height: 41px !important;
  }

  .page4 .col-11 {
    border-radius: 10px;
    min-height: 260px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
}

@media (min-width: 412px) and (max-width: 413px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads2_For_page1 {
    /* min-height: 474px; */
    min-height: 430px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 64px; */
  }

  .ads3_For_page1 {
    min-height: auto;
    /* min-height: 55px; */
  }

  .page2 .col-3 {
    min-height: 114px;
  }

  .page3 .col-5 {
    border-radius: 10px;
    width: 47.666667%;
    min-height: 234px;
  }

  .ads3_For_page3 {
    min-height: 41px !important;
  }

  .page4 .col-11 {
    border-radius: 10px;
    min-height: 260px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
}

@media (min-width: 414px) and (max-width: 429px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads2_For_page1 {
    /* min-height: 474px; */
    min-height: 430px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 64px; */
  }

  .ads3_For_page1 {
    min-height: auto;
    /* min-height: 55px; */
  }

  .page2 .col-3 {
    min-height: 114px;
  }

  .ads3_For_page3 {
    min-height: 41px !important;
  }

  .page3 .col-5 {
    width: 46.666667%;
    min-height: 230px;
    border-radius: 10px;
  }

  .page4 .col-11 {
    border-radius: 10px;
    min-height: 275px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
}

@media (min-width: 430px) and (max-width: 539px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads2_For_page1 {
    min-height: 430px;
    /* min-height: 494px; */
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 67px;  */
  }

  .ads3_For_page1 {
    min-height: auto;
    /* min-height: 59px; */
  }

  .page2 .col-3 {
    min-height: 119px;
  }

  .ads3_For_page3 {
    min-height: 45px !important;
  }

  .page3 .col-5 {
    width: 46.666667%;
    min-height: 241px;
    border-radius: 10px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
  .page4 .col-11 {
    border-radius: 10px;
    min-height: 288px;
  }
}

@media (min-width: 540px) and (max-width: 767px) {
  .pageDate p {
    font-size: 10px !important;
  }

  .ads2_For_page1 {
    min-height: 430px;
    /* min-height: 638px; */
  }

  .ads1_For_page1 {
    /* min-height: 87px; */
    min-height: auto;
  }

  .ads3_For_page1 {
    min-height: auto;
    /* min-height: 76px; */
  }

  .page2 .col-3 {
    min-height: 208px;
  }

  .ads3_For_page3 {
    min-height: 45px !important;
  }

  .page3 .col-5 {
    width: 46.666667%;
    /* min-height: 241px; */
    min-height: 314px;
    border-radius: 10px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
  .page4 .col-11 {
    border-radius: 10px;
    min-height: 364px;
  }
  .page2 .col-3{
    min-height: 175px;
  }
}

@media (min-width: 768px) and (max-width: 819px) {
  .ads2_For_page1 {
    min-height: 400px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 58px; */
  }

  .ads3_For_page1 {
    min-height: auto;
    /* min-height: 48px; */
  }

  .page2 .col-3 {
    min-height: 208px;
  }

  .ads3_For_page3 {
    min-height: 45px !important;
  }

  .page3 .col-5 {
    width: 48.666667%;
    min-height: 451px;
    border-radius: 10px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
  .page4 .col-11 {
    border-radius: 10px;
    min-height: 489px;
  }
}

@media (min-width: 820px) and (max-width: 852px) {
  .ads2_For_page1 {
    min-height: 430px;
  }

  .ads1_For_page1 {
    /* min-height: 58px; */
    min-height: auto;
  }

  .page2 .col-3 {
    min-height: 208px;
  }

  .ads3_For_page3 {
    min-height: 45px !important;
  }

  .page3 .col-5 {
    width: 48.666667%;
    min-height: 451px;
    border-radius: 10px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
  .page4 .col-11 {
    border-radius: 10px;
    min-height: 489px;
  }
}

@media (min-width: 853px) and (max-width: 911px) {
  .ads2_For_page1 {
    min-height: 430px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 58px; */
  }

  .page2 .col-3 {
    min-height: 208px;
  }

  .ads3_For_page3 {
    min-height: 45px !important;
  }

  .page3 .col-5 {
    width: 48.666667%;
    min-height: 451px;
    border-radius: 10px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
  .page4 .col-11 {
    border-radius: 10px;
    min-height: 489px;
  }
}

@media (min-width: 912px) and (max-width: 1023px) {
  .ads2_For_page1 {
    min-height: 430px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 58px; */
  }

  .ads3_For_page1 {
    min-height: auto;
    /* min-height: 40px; */
  }

  .page2 .col-3 {
    min-height: 208px;
  }

  .ads3_For_page3 {
    min-height: 45px !important;
  }

  .page3 .col-5 {
    width: 48.666667%;
    min-height: 451px;
    border-radius: 10px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
  .page4 .col-11 {
    border-radius: 10px;
    min-height: 489px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .ads2_For_page1 {
    min-height: 586px;
  }

  .ads1_For_page1 {
    min-height: auto;
    /* min-height: 58px; */
  }

  .ads3_For_page1 {
    /* min-height: 69px; */
  }

  .page2 .col-3 {
    min-height: 133px;
  }

  .page3 .col-5 {
    width: 47.666667%;
    border-radius: 10px;
    min-height: 299px;
  }

  .ads3_For_page3 {
    min-height: 45px !important;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
  .page4 .col-11 {
    border-radius: 10px;
    min-height: 322px;
  }
}

@media (min-width: 1280px) and (max-width: 2544px) {
  .ads2_For_page1 {
    /* min-height: 703px; */
    min-height: 470px;
  }

  .ads1_For_page1 {
    min-height: auto;
    min-height: 96px;
  }

  .page2 .col-3 {
    min-height: 208px;
  }

  .page3 .col-5 {
    width: 47.666667%;
    border-radius: 10px;
    min-height: 360px;
  }

  .ads3_For_page3 {
    min-height: 45px !important;
  }
  .page4 .col-11 {
    border-radius: 10px;
    min-height: 385px;
  }
  .ads2_For_page4{
    min-height: 99px !important;
  }
}


/* ---------------------------------------------------HOME------------------------------------------------------------------- */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(184.16deg, #D9D9D9 3.48%, rgba(255, 255, 255, 0) 92.13%);
  background-image: url('./images/homeImage.jpg');
  padding-top: 8rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden !important;
}

.home-btn {
  padding: 8px;
  font-size: 30px;
  font-weight: 700;
  background: #FFFFFFCC;
  box-shadow: 1px 6px 6px 0px #00000040;
  color: #064270;
  border: 2px solid;
  border: 2px solid rgb(7, 31, 73);
  border-radius: 27px;
  width: 243px;
}

.home-btn:hover {
  color: white;
  border: 2px solid white;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%);
  box-shadow: 1px 6px 4px 0px #00000040;
}

.back-image {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  position: absolute;
  width: -webkit-fill-available;
}

.home-contain-first-price {
  font-size: 24px;
  text-align: center;
  margin-bottom: 0rem;
}

.home-content {
  background-color: rgba(186, 215, 242, 0.5);
  color: #323271;
}

.home-head p {
  color: azure;
}

.home-head {
  color: #042444;
}

.home-heading {
  color: white;
  text-align: center;
  font-size: 5px;
  font-size: 56px;
  font-weight: 700;
}

.home-contain {
  background: linear-gradient(180deg, #1361C9 0%, #053E65 100%);
  border-radius: 9px;
  color: white;
}

.home-contain p {
  margin-top: 0rem !important;
}

@media (min-width: 360px) and (max-width: 680px) {
  .home-btn {
    margin-top: 0rem !important;
  }

  .home {
    padding-top: 5rem;
  }

  .home_details {
    font-size: 6px !important;
  }

  .home-last-contain-p {
    font-size: 8px !important;
  }
  .image-aling{
    text-align: center !important;
  }
  .image-aling-start{
    text-align: center !important;
  }
}
/* For mobile devices */
@media (max-width: 680px) {
  .gst_footer {
    text-align: center !important;
  }

  .slot-head {
    font-size: 15px;
  }

  .back-image {
    background-color: white;
  }

  .home-heading {
    font-size: 36px;
  }

  .home-btn {
    padding: 8px;
    font-size: 20px;
    font-weight: 700;
    background: #FFFFFFCC;
    box-shadow: 1px 6px 6px 0px #00000040;
    color: #064270;
    border: 2px solid white;
    border-radius: 27px;
    width: 100px;
  }

  .logo-img {
    width: 100%;
    height: 50px
  }

  .home-last-contain-p {
    font-size: 9px;
    margin-top: 0;
    margin-bottom: 0rem;
  }

  .home_details {
    font-size: 5px;
  }

}

@media (min-width: 768px) and (max-width: 1025px) {
  .home-heading {
    font-size: 54px !important;
  }
  .section7_img {
    width: 100% !important;
    height: auto;
  }
  .image-aling{
    text-align: center;
  }
  .home {
    padding-top: 19rem;
  }

  .home-btn {
    padding: 8px;
    font-size: 30px;
    font-weight: 700;
    background: #FFFFFFCC;
    box-shadow: 1px 6px 6px 0px #00000040;
    color: #064270;
    border: 2px solid;
    border: 2px solid rgb(7, 31, 73);
    border-radius: 27px;
    width: 243px !important;
  }

  .gst_footer {
    text-align: center !important;
  }

  .back-image {
    background-color: white;
  }

  .popUp-three-btn {
    display: none !important;
  }

  .home-last-contain-p {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0rem;
  }

}

/* For tablets */
@media (min-width: 681px) and (max-width: 768px) {
  .home-heading {
    font-size: 33px;
  }
  .section7_img {
    width: 100%;
    height: auto;
  }
  .image-aling{
    text-align: center !important;
  }
  .home-btn {
    padding: 6px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    background: #FFFFFFCC;
    box-shadow: 1px 6px 6px 0px #00000040;
    color: #064270;
    border: 2px solid white;
    border-radius: 27px;
    width: 172px !important;
  }

  .gst_footer {
    text-align: center !important;
  }

  .popUp-three-btn {
    display: none !important;
  }

  .back-image {
    background-color: white;
  }

  .home-last-contain-p {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0rem;
  }
}



.home-contain_p3 {
  font-size: small;
  text-align: center;
}

.home-contain_p1 {
  font-size: larger;
  text-align: center;
}

.homa_main {
  margin: 2px;
}

.home-last-contain {
  position: relative;
  margin-top: auto;
  overflow-y: auto;
  background: linear-gradient(181.48deg, #D9D9D9 1.29%, rgba(255, 255, 255, 0.04) 98.78%);
}

.home_details {
  font-size: 15px;
  color: #09025E;
  background-color: white;
  border-radius: 2px;
}

.home-modal .modal-content {
  color: #200862 !important;
  background: linear-gradient(180deg, #89BAFC 0%, #DCF1FF 100%) !important
}
.slot-modal .modal-content{
  color: #200862 !important;
  padding: 10px;
  background: linear-gradient(180deg, #89BAFC 0%, #DCF1FF 100%) !important
}
.slot-modal .modal-header{
  border-bottom: none;
}
.slot-modal .btn-close{
  background-color: #0056b3;
  color: white !important;
  border-radius: 100px;
}
.slot-modal .headings{
  margin-left: 45%;
}

.home-modal ul li {
  font-size: 20px;
  padding: 2px;
}

.modal-li {
  text-align: left !important;
}

.modal_heading {
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%);
}

.border_col {
  border: 2px solid white;
  height: 90%;
  background: white
}

.col-1-border {
  width: 3.333333% !important;
}

.popUp-three-btn {
  position: absolute;
  left: -25px;
}

.popUp-three-btn img {
  cursor: pointer;
}


.modal2-image-div {
  border: 1px dashed black;
  border-radius: 10px;
}

.modal2_img {
  border-radius: 10px;
}

.popUp-three-btn button {
  background: none;
  border: none;
  padding: 0;
  margin-top: 10px;
  cursor: auto;
}


/*----------------------------------------------------WHY PAPER INSERTIONS----------------------------------------------------------------- */
.whyPaper_insertion1 {
  border-radius: 9px;
  padding: 10px;
  color: white;
  background: linear-gradient(180deg, #13C9A8 0%, #053E65 100%);
}

.whyPaper_insertion2 {
  border-radius: 9px;
  padding: 10px;
  color: white;
  background: linear-gradient(180deg, #135CC9 0%, #053E65 100%);

}

.whyPaper_insertion3 {
  border-radius: 9px;
  padding: 10px;
  color: white;
  background: linear-gradient(180deg, #138DC9 0%, #053E65 100%);

}

.how_to_ads_book {
  border: 3px dashed #000000;
}

/*-------------------------------------------------NAVBAR----------------------------------------------------------------- */
.nav-link {
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
  color: #032841;

}
.pre_booking_btn{
  width: 165px;
  color: white;
  padding: 2px;
  font-size: 16px;
  border: 2px solid white;
  border-radius: 20px;
  background: linear-gradient(81.92deg, #2DE05F 16.44%, #24AB42 52.47%, #2DE05F 81.23%);
  box-shadow: 0px 4px 4px 0px #00000040;

  /* background: linear-gradient(180deg, #1260C6 0%, #06406B 100%); */
}
.pre_booking_btn:hover{
  background: linear-gradient(81.92deg, #14752f 16.44%, #14752f.47%, #14752f 81.23%);
box-shadow: none;
}
.navbar_btn {
  width: 165px;
  color: white;
  padding: 10px;
  font-size: 18px;
  border: 2px solid white;
  border-radius: 20px;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%);
  box-shadow: 1px 6px 4px 0px #00000040;

}

.navbar_btn:hover {
  border: 2px solid white;
  background: linear-gradient(180deg, #1267d6 0%, #064678 100%);
  box-shadow: none !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-link {
    font-size: 10px !important;
  }
  
  .navbarLogo {
    width: 90%;
    height: auto;
  }

  .booked-btn {
    padding: 5px;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    font-size: small;
  }
}

.booked-btn {
  background-color: #14304b;
  border: none;
  padding: 15px;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
}

.booked-btn:hover {
  background-color: #24496b;
}

/* ----------------------------------------------------HOW TO ADS BOOKED ---------------------------------------------------------- */
.how_To_adsBooked {
  border: 2px solid #14304b;
  border-bottom: 5px solid #14304b;
  border-right: 5px solid #14304b;
  border-radius: 14px;
}

.adsBooked_step {
  font-weight: 900;
  color: #14304b;
  /* color: red; */
  font-size: xx-large;
  font-family: 'Times New Roman', Times, serif;
}

.arrow {
  color: #14304b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  /* font-size: 60px; */

}

@media screen and (max-width: 991px) and (min-width: 230px) {
  .arrow {
    font-size: 40px;

  }
 
}

.layoutpage {
  font-size: 100px;
  color: gray;
}

.adsBooked_step_name {
  color: #14304b;
  font-weight: 600;
  font-family: 'Times New Roman', Times, serif;
  font-size: xx-large;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

img.select_1_image {
  width: 100%;
}

img.select_2_image {
  width: 100%;
}

img.select_3_image {
  width: 100%;
}

/* ----------------------------------SECTION 1 ---------------------------------------- */
.section1 {
  margin-top: 130px;
  text-align: justify;
}

.section1 p {
  text-align: justify;
}


.section1 .containe1 {
  border-radius: 5px;
  background: linear-gradient(180deg, #33785F 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 21px 0px #00000040;
}

.section1 .containe2 {
  border-radius: 5px;
  background: linear-gradient(180deg, #AAA752 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 21px 0px #00000040;
}

.section1 .containe3 {
  border-radius: 5px;
  background: linear-gradient(180deg, #C8A052 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 21px 0px #00000040;
}

.section1 .containe4 {
  border-radius: 5px;
  background: linear-gradient(180deg, #35BDAD 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 21px 0px #00000040;
}

.section1 .col-md-5 {
  flex: 0 0 auto;
  width: 25.666667%;
  height: 45.666667%;

}

.image_contain {
  background: linear-gradient(133.38deg, #EA3B3B 54.89%, #A61D1D 94.62%);
  border-radius: 60px !important;
  transform: rotate(44deg);
  padding: 55px;
  position: absolute;
  top: 2165px;
  left: 494px;
}

.image_contain img {
  width: 100% !important;
  transform: rotate(316deg);
}

.section1_heading {
  color: #14304b;
  text-align: center;
  font-weight: 600;
}

.section1_icon {
  color: #14304b;
  font-size: xx-large;
}

@media (max-width: 599px) {
  .section1 p {
    font-size: 5px;
    text-align: justify;
  }

  .page2 .col-3 p {
    margin-top: 0;
    margin-bottom: 0rem !important;
  }

  .page .col-3 p {
    margin-top: 0;
    margin-bottom: 0rem !important;
  }

}

@media (min-width: 600px) and (max-width: 1023px) {
  .section1 p {
    font-size: 10px;
    text-align: justify;
  }

}

/* ----------------------------------SECTION 2 ---------------------------------------- */
.section2 {
  background-image: url('./images/offficeImge.jpeg');
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1100px) and (min-width: 992px) {
  .section2_icon_div {
    width: 100% !important;
  }
}

@media (max-width: 820px) {
  .section2-review p {
    font-size: x-small !important;
  }
}

.section2_icon_div {
  background: #FFFFFFD6;

}

.section2_p {
  text-align: justify;
  color: black;
}

.section2-heading {
  color: black;
  font-weight: 600;
}

.section2_heading2 {
  background: #FFFFFFC4;
  display: inline-block;
  border: 2px solid #3C25C9;
  border-radius: 7px;
}
.section2_heading1 {
  background: #FFFFFFC4;
  display: inline-block;
  border-radius: 7px;
}
.section2-icon {
  font-size: -webkit-xxx-large;
  color: #14304b;
}

.section2-review {
  border-left: 1px solid #14304b;
  text-align: center;
  align-items: center;
}
.section2-review1 {
  border-left: none;
  text-align: center;
  align-items: center;
}

.section2-review p {
  font-size: large;
  text-align: center;
  align-items: center;
}

/* ----------------------------------SECTION 3 ---------------------------------------- */

.section3 {
  border-radius: 9px;
  padding: 18px;
  color: white;
  background: linear-gradient(180deg, #35BDAD 0%, #505253 100%);
}

.section3_content {
  padding: 10px;

}

/* ----------------------------------SECTION 4 ---------------------------------------- */
.section4_content {
  padding: 10px;
}

.section4 {
  background-color: #9f9fc9;
  text-align: center;
  color: white;
}

/* ----------------------------------SECTION 5 ---------------------------------------- */
.section5 p {
  text-align: justify;
}

/* ---------------------------------- SECTION 6 ---------------------------------------- */
#advantages {
  background: linear-gradient(187.79deg, #F7F7F7 41.57%, #ECECEC 94.36%);
}

#whyAds {
  background: linear-gradient(187.79deg, #F7F7F7 41.57%, #ECECEC 94.36%);
}

.section6 .card {
  padding: 10px;

}

.section6-content {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  color: white;
  background: linear-gradient(180deg, #33785F 0%, #053E65 100%);
}

.section6-content1 {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  color: white;
  background: linear-gradient(180deg, #1361C9 0%, #053E65 100%);
}

.text-muted {
  color: white !important;
}

.subheading {
  text-align: center;
  color: white;
}

/* ---------------------------------- SECTION 7 ---------------------------------------- */
@media (max-width: 767px) {
  .section7-row {
    flex-direction: column;
  }

  .content-first {
    order: 1;
  }

  .image-second {
    order: 2;
    margin-bottom: 20px;
  }

  .gst_footer {
    text-align: center;
  }
}

.section7 {
  margin-top: 20px;
}

.section7 p {
  font-size: 20px;
}

.section7_heading {
  color: #1158b0;
  /* color: #0C0181; */
}
.image-aling{
  text-align: end;
}
.image-aling-start{
  text-align: start;
}
#adsSize p {
  margin-left: 20px;
  margin-right: 20px;
}

.section7_content p {
  margin-left: 100px;
  margin-right: 100px;
}

.section7_img {
  width: 50%;
  height: auto;
}

/* ---------------------------------- FOOTER ---------------------------------------- */
.social_icon {
  color: #042444;
  font-size: 30px;
}

.social_icon:hover {
  color: #042444;
}

.text-reset {
  text-decoration: none;
}

.main {
  background: linear-gradient(90deg, #E3E2E2 0%, #717171 100%);
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%);
  box-shadow: 1px 6px 4px 0px #00000040;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top:hover {
  color: white;
  box-shadow: none !important;
  background: linear-gradient(180deg, #1267d6 0%, #064678 100%);
}

.copyright {
  background: linear-gradient(270deg, #A1A2A4 100%, #909398 0%);
  --bs-gutter-x: none !important;
  color: #0C0181 !important;
}

.footer_main_logo {
  margin-top: -33px;
}

.gst_footer {
  text-align: end;
}

@media (max-width: 1027px) {
  .footer_email {
    font-size: 10px;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


/* ----------------------------------SELECT PAGE---------------------------------------- */

.page {
  height: 600px;
  border-radius: 10px;
}

.page1 {
  background-color: #a0a0a0 !important;
}

.page2 {
  background-color: #a0a0a0 !important;
}

.page3 {
  background-color: #a0a0a0 !important;
}

.page4 {
  background-color: #a0a0a0 !important;
}

.page4 .col-11 {
  border-radius: 10px;
  width: 99.666667%;
}

.page2 .col-3 {
  width: 32%;
  height: auto;
  border-radius: 10px;
}
.page3 .col-5 {
  border-radius: 10px;
  height: auto;
  /* width: 47.666667%; */

}


@media (max-width: 767px) {
  .page2 .col-3 {
    width: 31%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .page2 .col-3 {
    width: 31%;
  }
}




@media only screen and (max-width: 1199px) and (min-width: 1025px) {
  /* .page3 .col-5 {
    border-radius: 10px;
    height: auto;
    width: 45.666667%;
} */
}

.notBooked {
  background-color: #c7c9cb !important;
}

.heading-image {
  padding: 0px;
  margin-bottom: 5px;
  border-radius: 10px;
  width: 100%;
}

.otherpage-head-img {
  height: 88px;
}

.allpage_book_btn {
  border: none;
  border: 2px solid white;
  border-radius: 10px;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%);
  box-shadow: 1px 6px 4px 0px #00000040;
  padding: 5px;
  margin-bottom: 10px;
  color: white
}

.allpage_book_btn:hover {
  color: white;
  box-shadow: none !important;
  background: linear-gradient(180deg, #1267d6 0%, #064678 100%);
}

.ads3_For_page3 {
  width: 99% !important;
  /* min-height: 150px !important; */
  height: auto;
}

.ads1_For_page4 {
  /* height: 300px !important; */
  height: auto;
}

.ads3_For_page4 {
  height: auto;
  /* height: 300px !important; */
}

.ads2_For_page1 {
  /* height: 400px */
  height: auto;
}

.ads1_For_page1 {
  width: 28%;
  height: auto;
  font-size: small;
}
.ads1_For_page1 p{
  margin-top: 0;
  margin-bottom: 0rem !important;
}
.ads3_For_page1 p{
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.ads3_For_page1 {
  height: auto;
}

.client-btn {
  background-color: #14304b;
}
.heading-image1 {
  border-radius: 15px;
  padding: 0px 5px 5px 0px;
  width: 70%;
}



@media (min-width: 768px) {
  .ads1_For_page1 {
    font-size: 10px;
    width: 30%;
  }
}

@media (min-width: 992px) {
  .ads1_For_page1 {
    font-size: 10px;
    width: 30%;
  }
}

@media (min-width: 1200px) {
  .ads1_For_page1 {
    width: 30%;
  }
}

@media (min-width: 1142px) {
  .ads1_For_page1 {
    width: 30%;
  }
}

@media (min-width: 1024px) {
  .ads1_For_page1 {
    width: 30%;
  }

}
@media (max-width: 1024px) {

  .page3 .col-5 {
    border-radius: 10px;
    height: auto;
    width: 47.666667%;
}
}


@media (min-width: 360px) and (max-width: 449px) {
  .ads1_For_page1 {
    width: 30%;
    font-size: 10px;
    height: auto;
  }
  .allpage_book_btn {
    font-size: 10px;
  }
  .ads1_For_page1 p {
    margin-bottom: 0rem !important;
  }

  h5 {
    font-size: 13px;
  }

  .page3 .col-5 {
    width: 47.666667%;
    /* min-height: 360px; */
    height: auto;
    border-radius: 10px;
  }

  .heading-image1 {
    border-radius: 10px;
    width: 70%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .ads1_For_page1 {
    font-size: 10px;
    /* height: 70px; */
    height: auto;
    width: 23%;
  }

  .heading-image1 {
    border-radius: 10px;
    width: 70%;
    height: auto;
    /* height: 98px; */
  }
}

@media (min-width: 1156px) {
  .ads1_For_page1 {
    width: 30%;
  }
}

/* ---------------------------------- INQUIRY ---------------------------------------- */

.inquiry {
  padding: 50px;
  background-image: url('./images/inquiry.png');
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
  border: 7px solid white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.inquiry_heading {
  color: #114f9b;
  /* color: #0C0181; */
  text-align: center;
}

.inquiryImage {
  border-radius: 10px;
  height: -webkit-fill-available;
  width: 60%
}

.form-control {
  box-shadow: 0px 4px 4px 0px #FFFFFF26;
}

.inquiry-btn {
  width: 165px;
  color: white;
  padding: 10px;
  font-size: 18px;
  border: 2px solid white;
  border-radius: 20px;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%);
  box-shadow: 1px 6px 4px 0px #00000040;
}

.inquiry-btn:hover {
  border: 2px solid white;
  color: white;
  box-shadow: none !important;
  background: linear-gradient(180deg, #1267d6 0%, #064678 100%);
}

.inquiryForm {
  border-radius: 10px;
  align-items: center;
  height: -webkit-fill-available;
}

@media (max-width: 991px) {
  .inquiryImage_div {
    margin-top: 10px;
  }

  .popUp-three-btn {
    display: none !important;
  }
}

.otp-modal .modal-content {
  background: linear-gradient(180deg, #FFFFFF 39.07%, #9FC3FA 100%) !important;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 120% !important;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #aaa;
}

.inquiry-h2 {
  color: #1158b0;
}

.otpInput {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.otp-modal .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center !important;
  text-align: center !important;
  border-bottom: none !important;
}

.otp-modal .modal-footer {
  border-top: none !important;
}

.otpInput::placeholder {
  letter-spacing: 10px !important;
  color: #000000 !important;
  font-size: 36px !important;
}

.otp_btn {
  margin-top: 10px;
  width: 50%;
  color: white !important;
  padding: 10px;
  font-size: 18px;
  border: 2px solid white !important;
  border-radius: 10px;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%) !important;
  box-shadow: 1px 6px 4px 0px #00000040;
  cursor: pointer;
}

.otp_btn:hover {
  background: #1260C6 !important;
}

.swal2-popup {
  background: linear-gradient(180deg, #FFFFFF 39.07%, #9FC3FA 100%);
}

/* -------------------------------SLOT---------------------------- */
.slotBox {
  border: 2px solid;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 1px 6px 4px 0px #00000040;
  cursor: pointer;
  background: #DCDBDB33;
  border-image: linear-gradient(180deg, #676ab1, #ce6c6c) 1;
}

.slotBox:hover {
  color: white;
  border: 2px solid white;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%) !important;
  box-shadow: 1px 6px 4px 0px #00000040;
}

.slot {
  background-image: url('./images/slotimage.png');
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center;
}

.slot-page-head {
  color: #167DC8;
  font-weight: 700;

}

.slot-dates {
  background: #EFEDEDB2;
}

.slotBox.disabled {
  /* background: #c4bebe; */
  display: none;
}

#page1 {
  cursor: pointer;
}

#page2 {
  cursor: pointer;
}

#page3 {
  cursor: pointer;
}

#page4 {
  cursor: pointer;
}

.slot-footer h1 {
  color: red;
  text-align: center;
  letter-spacing: 20px;
  font-size: 51px;
  font-weight: 400 !important;
  background: -webkit-linear-gradient(90deg, #76C9EB 0%, #EEC17E 22.5%, #EC9E64 50.5%, #9DB57B 74.5%, #D5D0BC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slot-modal-btn {
  width: 100px;
  border: 2px solid white !important;
  border-radius: 12px;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%) !important;
  box-shadow: 1px 6px 4px 0px #00000040;
  cursor: pointer;
}
.slot-modal-btn:hover{
  border: 2px solid white;
  box-shadow: none !important;
  background: linear-gradient(180deg, #1267d6 0%, #064678 100%) !important;
}

/* ---------------------------------top choice-------------------------------------- */

.topchoice_main_div {
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 0 40px rgba(0, 0, 1, 0.416);

}

.topchoice_main_div {
  position: relative;
}

.popup-box {
  display: none;
  position: absolute;
  top: calc(10%);
  left: 0;
  background-color: rgba(104, 97, 97, 0.9);
  border: 1px solid #ccc;
  border-radius: 10px;
  color: white;
  padding: 10px;
  width: 100%;
  z-index: 999;
}

.expandable-text {
  overflow: hidden;
  max-height: 16.6em;
  cursor: pointer;
  transition: max-height 0.3s ease;
}

.expandable-text:hover+.popup-box {
  display: block;
  opacity: 1;
}

.topchoice-btn {
  width: 40% !important;
  background: none !important;
  color: #042444;
  border: 2px solid #042444;

}

.topchoice-btn:hover {
  border: 2px solid white !important;
  box-shadow: none !important;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%) !important;
}

.topchoice-booking-btn {
  border: 2px solid white;
  border-radius: 10px;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%);
  box-shadow: 1px 6px 4px 0px #00000040;
  width: 50% !important;
}

.topchoice-booking-btn:hover {
  border: 2px solid white;
  background: linear-gradient(180deg, #1267d6 0%, #064678 100%);
  box-shadow: none !important;
  width: 50% !important;
}

/* ------------------------privacy policy------------------------------ */

.privacy_Image {
  background-image: url('./images/ahm.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  color: white;
  padding: 8rem;
  text-align: center;
  justify-items: center;
}

.privacy ul li {
  margin-top: 33px;
}

.page_h4 {
  color: #0056b3;
  border-bottom: 2px solid #0056b3;
}



img.red-tag {
  /* width: 130px; */
  width: 40%;
  max-width: 130px;
  height: 70px;
  float: left;
  position: absolute;
  left: -15px;
  top: 10px;
}

img.yellow-tag {
  width: 40%;
  max-width: 130px;
  height: 70px;
  float: left;
  position: absolute;
  left: -15px;
  top: 10px;
}




.view-btn {
  color: white;
  /* padding: 10px; */
  font-size: 15px;
  border: 2px solid white;
  border-radius: 15px;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%);
  box-shadow: 1px 6px 4px 0px #00000040;
}
.view-btn:hover{
  color: white;
  font-size: 15px;
  border: 2px solid white;
  border-radius: 15px;
  background: linear-gradient(180deg, #1260C6 0%, #032946 100%);
  box-shadow: none;
}


.modal-content {
  width: 100% !important
}




/* ---------------------successful page------------------- */
.thankyou-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('./images/ahm.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.thankyou-row {
  background: #EFEDEDA6;
}

.thankyou_header h1 {
  color: white;
  font-size: 86px !important;
}

.thankyou_page_header2 {
  color: #043665;
  font-size: 66px;
}

@media (max-width: 680px) {
  .thankyou_header h1 {
    color: white;
    font-size: 60px !important;
  }

  .thankyou_page_header2 {
    color: #043665;
    font-size: 38px;
  }

  .popUp-three-btn {
    display: none !important;
  }

  .slot-footer h1 {
    letter-spacing: 10px !important;
  }
}

@media (max-width: 280px)  {
  .thankyou_header h1 {
    color: white;
    font-size: 40px !important;
  }

  .thankyou_page_header2 {
    color: #043665;
    font-size: 30px;
  }

  .slot-footer h1 {
    text-align: center;
    letter-spacing: 20px;
    font-size: 20px;
    font-weight: 400 !important;
  }

  .popUp-three-btn {
    display: none !important;
  }

  .home-heading {
    font-size: 20px;
  }

  .home-contain_p1 {
    font-size: small;
    text-align: center;
  }

  .home-contain_p3 {
    font-size: x-small;
    text-align: center;
  }

  .home_details {
    font-size: 6px !important;
  }

  .home-last-contain-p {
    font-size: 8px !important;
  }

  .home-contain-first-price {
    font-size: 18px;
    text-align: center;
    margin-bottom: 0rem;
  }

  .page2 .col-3 {
    width: 27%;
    height: auto;
    /* height: 100px; */
    /* padding: 10px; */
    font-size: 10px;
    border-radius: 10px;
  }

  .page2 .col-3 p {
    margin-top: 0;
    margin-bottom: 0rem;
  }

  .pageDate h5 {
    font-size: 10px;
  }

  .page3 .col-5 {
    /* width: 43.666667%; */
    /* min-height: 255px; */
    height: auto;
    /* padding: 10px; */
    border-radius: 10px;
  }
}

.logo-for-page {
  width: 70%;
  height: 100%;
}

.pageDate {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.pageScan {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}


div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: .25em;
  border: 3px solid white !important;
  box-shadow: 1px 6px 4px 0px #00000040;
  background: linear-gradient(180deg, #1260C6 0%, #06406B 100%) !important;
  /* background: linear-gradient(180deg, #FFFFFF 39.07%, #9FC3FA 100%) !important; */
  color: #fff;
  font-size: 1em;
}

.swal2-title {
  color: #19CE65 !important;

}

.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}

.page2_all_ads p{
  margin-top: 0;
  margin-bottom: 0rem !important;

}
.loading{
	width: 10%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: rotateAnimation 2s linear infinite;
}
@keyframes rotateAnimation {
	from {
	  transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
	  transform: translate(-50%, -50%) rotate(360deg);
	}
  }



  /* ---------------success story ------------- */

  .success_Story{
    background: linear-gradient(180deg, #F2F2F2 0%, #AAD4FA 82.06%);
    padding-top: 10rem;
  }
  .successStory-leftSide{
   background: #FFFFFF38;
   border-radius: 20px;
  }
  .successStory-leftSide p{
   font-weight: 500;
   color: white;
   }
  .nameOfclient{
    color: yellow;
  }
  .main-content-successStory{
    margin-top: -32px;
    background-image: url('./images/successStory-bg.png');
  }
  .timeBox{
    border-radius: 4px;
    background-color: blue;
    color: white;
    padding: 3px;
  }
  .successStory-right-Side{
    font-size: 50px;
    background-image: url('./images/aditi.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
  }
  .successStory-right-Side p{
    background: #FFFFFF38;
    border-radius: 20px;
    /* padding: 10px; */
  }

  .adsImage{
    background: none !important;
    border-radius: 10px !important;
    padding: 0px;
    height: auto;
  }