@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
/* Custom styles for the sidebar */
.body-div {
  font-family: "Lexend", sans-serif;

  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}
#wrapper {
  height: 100%;
  overflow: hidden;
}
h1,
p {
  margin: 0 0 1em;
}
* {
  --main-bg-color: #000;
  --light-green-color: #7be063;
  --white-color: #fff;
  --black-text-color: #000;
  --dark-green-color: #344f35;
}
#wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

#sidebar-wrapper {
  min-width: 250px;
  max-width: 250px;
  /* height: 100vh; */
  overflow-y: auto;
}

#page-content-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/**/
.cat-mn-crd .card {
  background: none;
  padding: 15px;
  width: 100%;
}
#page-content-wrapper {
  background-color: #000;
}
/**/
@media (max-width: 768px) {
  #sidebar-wrapper {
    display: none;
  }
  #sidebarToggle {
    display: block !important;
  }
  #closeSidebar {
    display: block;
    margin-top: 150px;
  }

  #sidebar-wrapper.toggled {
    display: block;
    position: absolute;
    width: 250px;
    height: 100%;
    z-index: 1000;
    background-color: white;
  }

  #sidebarBackdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  #sidebarBackdrop.d-none {
    display: none;
  }
  .cat-mn-crd {
    justify-content: center;
  }
  .search-bar-main {
    width: 300px;
    max-width: 300px;
    margin-left: auto;
  }
  .suc-story .sidebar-heading {
    width: 300px;
    z-index: 1;
    position: absolute;
    top: 30px;
  }

  .sucess_storynav {
    margin-bottom: 40px;
  }
  .category-list[aria-expanded="true"] .fa-bars:before {
    content: "\f00d";
  }
  .sidebar-cat-toggle {
    display: block !important;
  }
  #sidebar-wrapper {
    position: absolute;
    height: 100%;
    z-index: 11;
  }

  /* #sidebarContent {
    margin-top: 120px;
  } */
}

/**/

@media (max-width: 600px) {
  .header.header-sucstory {
    /* margin-top: 70px; */
    height: 200px;
  }
  .header-sucstory .header-content {
    margin-top: 0px;
  }
  .header-sucstory .header-image {
    max-width: 82%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .header.header-sucstory {
    height: 350px;
  }
  .header-sucstory .header-content {
    margin-top: 0x !important;
  }
  .header-sucstory .header-content-list {
    margin-top: 115px !important;
  }
  .header.header-sucstory-list {
    height: 430px !important;
  }
  .header-sucstory .header-image {
    max-width: 75%;
  }
  .header-sucstory-profile .header-image {
    margin-top: 165px !important;
  }
}

@media only screen and (min-width: 769px) {
  #sidebarToggle {
    display: none;
  }
  #closeSidebar {
    display: none;
  }
  .suc-story .sidebar-heading {
    width: 300px;
    z-index: 1;
    position: absolute;
    top: 10px;
  }
  #sidebarContent {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 769px) {
  .sidebar-cat-toggle {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  #sidebarToggle {
    display: none;
  }
  #closeSidebar {
    display: none;
  }
  .search-bar-main {
    width: 500px;
    margin-left: auto;
  }
  .suc-story .sidebar-heading {
    width: 300px;
    z-index: 1;
    position: absolute;
    top: 10px;
  }
  #sidebarContent {
    margin-top: 100px;
  }
}

.main-content-section {
  background: #000;
  height: 100%;
  background-image: url(../images/home_bg5.png);

  background-size: cover;
  background-repeat: no-repeat;
}
.sucess_storynav {
  background: linear-gradient(180deg, #88ff8d66 3.48%, rgb(0, 0, 0) 92.13%);
  background-color: #000;
}
.suc-story#sidebar-wrapper {
  background: var(--dark-green-color);
}
.suc-story#sidebar-wrapper .side-nav-contetn #sidebarContent {
  padding-right: 25px;
}

.category-list.list-group-item {
  background: #000000;
  box-shadow: 0px 0px 10px 0px #88ff8d66;

  height: 37px;
  margin-bottom: 10px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  color: var(--light-green-color);
  font-weight: 700;
  font-size: 18px;
  line-height: 37px;
  border: none;
}
.category-list {
  padding-top: 0px !important;
}
.suc-story .sidebar-heading {
  color: var(--black-text-color);
  background-color: var(--light-green-color);
  height: 45px;
  font-size: 20px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0px 3px 15px 0px #00000040;
}

.category-list-detail-title {
  color: var(--white-color);
  background-color: var(--dark-green-color);
  border: none;
}

.category-list-detail-title::before {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";

  color: var(--light-green-color);
  margin-right: 7px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 7px solid #7be063;
}

.sidebar-heading span {
  padding-left: 1rem;
  font-weight: 700;
}

.category-list-detail-title {
  position: relative;
}

.category-content-main .card-body {
  background-color: #00000000 !important;
}
.category-content-main .cat-sub-title {
  color: var(--white-color);
  font-size: 18px;
  font-weight: 700;
}
.category-content-main .cat-sub-text {
  color: var(--light-green-color);
  font-size: 15px;
  font-weight: 700;
}

.category-content-main .card {
  border: none;
  background: transparent;
}
.cat-img {
  height: 230px;
  object-fit: cover;
  object-position: top;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background: linear-gradient(
    180deg,
    #88ff8d 58.87%,
    rgba(82, 153, 85, 0) 100%
  );
  padding: 4px;
}
.category-content-main .card {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

/*search icon */

.has-search .form-control {
  padding-left: 3.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-top: 4px;
  margin-left: 5px;
}

.search-bar-input,
.search-bar-input:focus {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: var(--light-green-color);
  height: 45px;
}

.form-control:focus {
  border: 1px solid var(--light-green-color);
  box-shadow: 0px 0px 10px 0px #88ff8d66;
}

.search-ic {
  background-color: var(--black-text-color);
  border-radius: 50px;
}
.fa-search.search-ic:before {
  color: var(--light-green-color);
}

.sidebar-cat-toggle {
  background-color: var(--black-text-color);
  color: var(--light-green-color);
  border: none;
}

.category-list-detail-title.list-group-item-action:focus,
.category-list-detail-title.list-group-item-action:hover {
  background-color: #0000005d;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  color: #fff;
}

.dropdown-toggle::after {
  opacity: 0;
}
.search-bar-main {
  margin-left: auto;
}

#closeSidebar {
  padding-bottom: 30px;
  color: var(--light-green-color);
}
.close-btn-main {
  width: 100%;
  text-align: right;
}

.body-div {
  background-color: var(--black-text-color);
}

.downarrow-cat {
  position: absolute;
  right: 6px;
  top: 4px;
  width: 27px;
  height: 27px;
}

.special-class {
  transform: rotate(-180deg);
}

/*pagination css anks*/
.category-content-main .pages {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 100% !important;
  width: 200px !important;
}
.list-group-item {
  padding: 7px;
}
.custom-reset-css {
  padding-top: 5px;
  padding-right: 12px;
  padding-bottom: 5px;
}
.category-list-detail-title.list-group-item-action:focus,
.category-list-detail-title.list-group-item-action:hover {
  width: 91%;
}
.category-content-main .page-number {
  cursor: pointer;

  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease;
}

.category-content-main #prevPage {
  margin-right: 20px;
}

.category-content-main #nextPage {
  margin-left: 20px;
}

.category-content-main .arrow {
  font-size: 1.2em;
}

.category-content-main .nav-text {
  font-size: 0.7em;
  letter-spacing: 0.3em;
}

.page-number.active {
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(40, 74, 41, 1) 100%
  );
}

.page-number {
  color: var(--light-green-color);
  font-weight: 700;
  font-size: 27px;
  padding: 27px;
}

.category-content-main {
  height: 100%;
  padding-bottom: 40px;
}

#nextPage,
#prevPage {
  background: transparent;
  color: var(--light-green-color);
  font-weight: 700;
  font-size: 27px;
  border-left: none;
  border-top: none;
}

#nextPage:disabled,
#prevPage:disabled {
  opacity: 50%;
}

.pagination {
  justify-content: center;
}

.category-content-main ul.page {
  padding-left: 0px !important;
}

.cat-mn-crd .card {
  width: 90%;
}

#sidebar-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.header.header-sucstory-profile {
  height: 300px !important;
}
.header.header-sucstory {
  height: 543px;
  /*height: 300px;*
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-sucstory .header-content {
  text-align: center;
}

.header-sucstory .header-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: Adds rounded corners to the image */
}
@media (max-width: 768px) {
  .header.header-sucstory {
    height: 175px !important;
  }
  .header.header-sucstory-list {
    height: 240px !important;
  }
  .header-sucstory .header-image {
    margin-top: 100px !important;
  }
  .header-sucstory-list .header-image {
    margin-top: 70px !important;
  }
}

.menu-item:nth-child(1) {
  margin-top: 100px;
}

.sidebar-cat-toggle:hover {
  background-color: var(--black-text-color);
  color: var(--light-green-color);
  border: 1px solid var(--light-green-color);
}

#sidebarContent {
  margin-top: 0px;
}

.sidebar-cat-toggle {
  margin-left: auto;
}
